const DocMenuConfig = [
  {
    pages: [
      {
        ModuleId: 24,
        heading: "dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },

  {
    heading: "craft",
    route: "/crafted",
    pages: [
      {
        ModuleId: 2,
        heading: "Market Insight",
        route: "/MarketInsight/GasPrices",
        svgIcon: "media/icons/duotune/graphs/gra003.svg",
        fontIcon: "bi-archive",
      },
      {
        ModuleId: 44,
        heading: "Flexible Procurement",
        route: "/FlexibleProcurement/PositionReportDashboard",
        svgIcon: "media/icons/duotune/finance/fin008.svg",
        fontIcon: "bi-archive",
      },
      {
        ModuleId: 10,
        sectionTitle: "Procurement",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            ModuleId: 10,
            heading: "Asset Register - Supplier",
            route: "/AssetRegisterSupplier/CZGraphs",
          },
        ],
      },
      {
        ModuleId: 7,
        heading: "Risk Management",
        route: "/notFound",
        svgIcon: "media/icons/duotune/technology/teh004.svg",
        fontIcon: "bi-sticky",
      },
      {
        ModuleId: 8,
        sectionTitle: "Bureau",
        svgIcon: "media/icons/duotune/finance/fin006.svg",
        fontIcon: "bi-shield-check",
        sub: [
          {
            ModuleId: 32,
            heading: "Asset Register List",
            route: "/bureauAssetRegisterSupplier",
          },
          {
            ModuleId: 33,
            sectionTitle: "Site Addittion Removal",
            route: "/notFound",
            sub: [
              {
                ModuleId: 33,
                heading: "Electricity",
                route: "/assetAdditionalRemovalElectric",
              },

              {
                ModuleId: 33,
                heading: "Gas",
                route: "/assetAdditionalRemovalGas",
              },
            ],
          },
          // {
          //   ModuleId: 10,
          //   heading: "MeterType",
          //   route: "/meterType",
          // },
          {
            ModuleId: 37,
            sectionTitle: "Last 'Actual' Meter Read Report",
            route: "/notFound",
            sub: [
              {
                ModuleId: 37,
                heading: "Electricity",
                route: "/lastAcutalReadElec",
              },

              {
                ModuleId: 37,
                heading: "Gas",
                route: "/lastAcutalReadGas",
              },
            ],
          },
          {
            ModuleId: 38,
            sectionTitle: "Last Bill Date Report",
            route: "/notFound",
            sub: [
              {
                ModuleId: 38,
                heading: "Electricity",
                route: "/lastBillDateElec",
              },

              {
                ModuleId: 38,
                heading: "Gas",
                route: "/lastBillDateGas",
              },
            ],
          },

          {
            ModuleId: 12,
            heading: "viewUsers",
            route: "/notFound",
          },
          {
            ModuleId: 13,
            heading: "upgradePlan",
            route: "/notFound",
          },
          {
            ModuleId: 14,
            heading: "shareAndEarn",
            route: "/notFound",
          },

          {
            ModuleId: 34,
            heading: "MeterType",
            route: "/meterType",
          },
          {
            ModuleId: 35,
            heading: "Last Actual Meter Read Report",
            route: "/notFound",
          },
          {
            ModuleId: 36,
            heading: "Last Bill Date Report",
            route: "/notFound",
          },
        ],
      },
      {
        ModuleId: 9,
        sectionTitle: "Performance Monitoring",
        svgIcon: "media/icons/duotune/electronics/elc004.svg",
        fontIcon: "bi-shield-check",
        sub: [
          {
            ModuleId: 39,
            sectionTitle: "Historic Consumption, Cost and CO2",
            sub: [
              {
                ModuleId: 39,
                heading: "Electricity - NHH",
                route: "/historicConsumptionElectricity",
              },
              {
                ModuleId: 39,
                //sectionTitle: "Performance Monitoring",
                heading: "Gas",
                route: "/historicConsumptionGas",
              },
            ],
          },

          {
            ModuleId: 40,
            sectionTitle: "Projected Consumption, Cost and CO2",
            sub: [
              {
                ModuleId: 40,
                heading: "Electricity - NHH",
                route: "/projectedConsumptionElectricity",
              },
              {
                ModuleId: 40,
               // sectionTitle: "Performance Monitoring",
                heading: "Gas",
                route: "/projectedConsumptionGas",
              },
            ],
          },

          {
            ModuleId: 41,
            sectionTitle: "Accrual Report",
            sub: [
              {
                ModuleId: 41,
                heading: "Electricity - NHH",
                route: "/AccrualsForElectrictyNoSplit",
              },
              {
                ModuleId: 41,
                //sectionTitle: "Performance Monitoring",
                heading: "Gas",
                route: "/AccrualsForGasNoSplit",
              },
            ],
          },

          // {
          //   ModuleId: 40,
          //   heading: "Projected Consumption",
          //   route: "/notFound",
          // },
          // {
          //   ModuleId: 41,
          //   heading: "Accural Report",
          //   route: "/notFound",
          // },
          // {
          //   ModuleId: 42,
          //   heading: "Accural Report Monthly",
          //   route: "/notFound",
          // },
        ],
      },
      {
        ModuleId: 15,
        heading: "Invoice Query Management",
        route: "/notFound",
        svgIcon: "media/icons/duotune/technology/teh004.svg",
        fontIcon: "bi-sticky",
      },
      {
        ModuleId: 16,
        heading: "Submit Meter Reads",
        route: "/notFound",
        svgIcon: "media/icons/duotune/technology/teh004.svg",
        fontIcon: "bi-sticky",
      },
      {
        ModuleId: 17,
        sectionTitle: "Reports and Alerts",
        route: "/modals",
        svgIcon: "media/icons/duotune/art/art009.svg",
        fontIcon: "bi-shield-check",
        sub: [
          {
            ModuleId: 18,
            heading: "Alerts",
            route: "/notFound",
          },
        ],
      },
      {
        ModuleId: 19,
        sectionTitle: "User Management",
        route: "/modals",
        svgIcon: "media/icons/duotune/art/art009.svg",
        fontIcon: "bi-shield-check",
        sub: [
          {
            ModuleId: 20,
            heading: "Profiles",
            route: "/notFound",
          },
          {
            ModuleId: 21,
            heading: "Roles",
            route: "/notFound",
          },
          {
            ModuleId: 22,
            heading: "Users",
            route: "/notFound",
          },
        ],
      },
      {
        ModuleId: 23,
        heading: "Configuration",
        route: "/notFound",
        //route: "/crafted/widgets/tables",
        svgIcon: "media/icons/duotune/technology/teh004.svg",
        fontIcon: "bi-sticky",
      },
      {
        ModuleId: 51,
        sectionTitle: "Document Exchange",
        svgIcon: "media/icons/duotune//files/fil011.svg",
        fontIcon: "bi-shield-check",
        sub: [
          {
            ModuleId: 52,
            heading: "Energy Market Information",
            route: "/CZEnergyMarketPriceReport",
          },
        ],
      },
      // {
      //   ModuleId: 1009,
      //   heading: "Document Exchange",
      //   route: "/CZEnergyMarketPriceReport",
      //   svgIcon: "media/icons/duotune//files/fil011.svg",
      //   fontIcon: "bi-sticky",
      // },
    ],
  },

  // {
  //   pages: [
  //     {
  //       ModuleId: 1,
  //       heading: "Procurement",
  //       route: "/AssetRegisterSupplier/CZGraphs",
  //       svgIcon: "media/icons/duotune/general/gen014.svg",
  //       fontIcon: "bi-archive",
  //     },
  //     {
  //       ModuleId: 2,
  //       heading: "Invoice Validation",
  //       route: "/bureauAssetRegisterSupplier/bureauAssetElectrcityList",
  //       svgIcon: "media/icons/duotune/general/gen014.svg",
  //       fontIcon: "bi-archive",
  //     },
  //   ],
  // },
];

export default DocMenuConfig;
